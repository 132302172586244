import { memo } from "react";
import { FaIcon } from "../../../../../../module/ui-kit/source/main/component/fa-icon/FaIcon";
import { ClockIcon, UploadIcon } from "../../../../Assets/SvgIcons";
import { LoaderIcon } from "../../../../../../module/ui-kit/source/main/component/loader-icon/LoaderIcon";
import * as styles from './Item.module.scss';

export const Item = memo(({ synchronized, title, time, stamps, error, handler, loading }) => {
    return <div className={styles.item} onClick={handler}>
        {loading
            ? <LoaderIcon cn={styles.item__loader} />
            : error == 'error'
                ? <FaIcon cn={styles.item__icon} icon={'fa-exclamation-triangle'} color={'var(--mobile-app-error-text-color)'} />
                : synchronized
                    ? <FaIcon cn={styles.item__icon} icon="fa-circle-check" type='regular' color='#43A047' size='xl' />
                    : <div className={styles.item__icon}>
                        <UploadIcon color='#304ffe' />
                    </div>}
        <div>
            <p className={styles.item__id}>{title}</p>
            <div className={styles.item__info}>
                <ClockIcon /> <p>{time}</p>
                <FaIcon cn={styles.item__lockIcon} icon="fa-lock" color='rgba(0, 0, 0, 0.43)' size='md' /> <p>{stamps}</p>
            </div>
        </div>
        {error == 'warning' && <FaIcon cn={styles.item__rightIcon} icon={'fa-wifi'} color={'#E5E537'} />}
    </div>
}, (prev, next) => prev.synchronized === next.synchronized && prev.loading === next.loading);