import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { LoaderIcon } from "../../../../../../module/ui-kit/source/main/component/loader-icon/LoaderIcon";
import { FaIcon } from '../../../../../../module/ui-kit/source/main/component/fa-icon';
import { Reader } from './Reader';
import sounds from '../../../../Assets/sounds.json';

import * as styles from './ScanScreen.module.scss';
import { validateQr } from '../../../../../../module/ui-kit/source/platform/form/extra/scaner/QrCodeValidate';
import { RepeatIcon } from '../../../../Assets/SvgIcons/RepeatIcon';
import { DefaultLayout } from '../../../../Layouts/DefaultLayout';

let errorTimeout;

export const ScanScreen = ({ onScan, back, historyPopHandler, title }) => {
    const [devices, setDevices] = useState([]);
    const [deviceId, setDeviceId] = useState(null);
    const [videoPermission, setVideoPermission] = useState(false);
    const [error, setError] = useState(null);
    const [codeError, setCodeError] = useState(null);
    const [ignoreDoubleScan, setIgnoreDoubleScan] = useState(false);
    const [canRotate, setCanRotate] = useState(true);
    const [cameraFacing, setCameraFacing] = useState("environment");

    const successAudio = new Audio("data:audio/wav;base64," + sounds.success);
    const playSuccess = successAudio.play.bind(successAudio);
    const failAudio = new Audio("data:audio/wav;base64," + sounds.fail);
    const playFail = failAudio.play.bind(failAudio);

    const cameraRotate = () => {
        if (canRotate) {
            const video = document.getElementById('uikit-scanner-video-wrapper')?.getElementsByTagName('video')[0];

            if (devices.length > 1 && (video?.readyState > 2 || error)) {
                clearTimeout(errorTimeout);

                setCameraFacing(prev => prev === 'user' ? 'environment' : 'user');
            }

            setCanRotate(false);
            setTimeout(() => setCanRotate(true), 1000);
        }
    }

    useEffect(() => {
        clearTimeout(errorTimeout);
        const video = document.getElementById('uikit-scanner-video-wrapper')?.getElementsByTagName('video')[0];
        if (video) video.pause();

        if (deviceId) {
            setError(null);

            errorTimeout = setTimeout(() => {
                const video = document.getElementById('uikit-scanner-video-wrapper')?.getElementsByTagName('video')[0];
                if (!(video?.readyState > 2)) setError(prev => prev ? prev : 'Ошибка источника');
            }, 5000)
        } else {
            setTimeout(() => {
                setDeviceId(prev => {
                    if (!prev) {
                        setError(prev => prev ? prev : 'Устройство не найдено')
                    }

                    return prev;
                })
            }, 5000)
        }
    }, [deviceId]);
    useEffect(() => {
        if (videoPermission && navigator.mediaDevices?.enumerateDevices) {
            navigator.mediaDevices.enumerateDevices()
                .then(function (devices) {
                    const videoDevices = devices.filter(({ kind, deviceId }) => kind === 'videoinput' && !!deviceId);

                    if (!videoDevices?.length) {
                        if (!error) {
                            setError('Видеоустройства не обнаружены.');
                        }
                        return;
                    }

                    setDevices(videoDevices);
                    setDeviceId(prev => prev
                        ? prev
                        : (videoDevices.find(({ label }) => label.toUpperCase().includes('BACK')) || videoDevices[0]).deviceId)
                })
                .catch(function (err) {
                    console.error('err', err);
                });
        } else if (videoPermission) {
            setError('Ошибка получения списка устройств. Возможно, браузер устарел.');
        }
    }, [videoPermission]);
    useEffect(() => {
        navigator.permissions.query({ name: 'camera' }).then(function (permissionStatus) {

            if (permissionStatus.state === 'prompt') {
                navigator.getUserMedia(
                    { video: true, audio: false },
                    () => { },
                    (error) => console.log(error));
            } else if (permissionStatus.state === 'denied') {
                setError('Нет разрешения на использование камеры. Проверьте настройки сайта.')
            }

            setVideoPermission(permissionStatus.state)

            permissionStatus.onchange = function () {
                setVideoPermission(true)
            };
        });

        const unlisten = historyPopHandler(back);

        return () => unlisten();
    }, []);

    const scan = async ({ result }) => {
        if (!ignoreDoubleScan) {

            if (validateQr(result)) {
                playSuccess();
                onScan(result.text)
            } else {
                playFail();
                setCodeError('Некорректный формат QR-кода')
                setIgnoreDoubleScan(true);

                setTimeout(() => {
                    setIgnoreDoubleScan(false);
                }, 1000)
            }
        }
    }

    return (
        <DefaultLayout back={back} title={title} screenCn={styles.screen}>
            <div className={styles.camera}>
                <div className={styles.camera__qrWrapper} id='uikit-scanner-video-wrapper'>
                    {error
                        ? <div className={styles.error}>
                            <FaIcon icon={'fa-video-slash'} cn={styles.error__icon} />
                        </div>
                        : <>
                            <Reader
                                cameraFacing={cameraFacing}
                                delay={500}
                                onScan={scan}
                            />
                            <LoaderIcon cn={styles.camera__loader} />
                        </>}
                </div>
                <div className={classNames(styles.camera__message, error || codeError ? styles.camera__errorMessage : '')}>
                    <p>{error || codeError ? error || codeError : 'Считайте QR-код'}</p>
                </div>
                <div className={styles.camera__rotate} onClick={cameraRotate}>
                    <RepeatIcon />
                </div>
            </div>
        </DefaultLayout>
    );
};
