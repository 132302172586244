import { construct, instance, register } from '../../module/ui-kit/source/platform/Container';
import { getUser, updateUser } from './Store';

const requestTokenMiddleware = (userStore, { request, params }) => {
    return request({
        ...params,
        headers: userStore.token && userStore.user?.id
            ? {
                ...params.headers,
                'Authorization': 'Basic ' + userStore.token
            }
            : params.headers
    })
}
const responseTokenMiddleware = (userStore, updateUser, navigateLogin, { response }) => {
    const header = response.headers.get("Authorization");

    if (response.status == 401) {
        return navigateLogin();
    }

    userStore.updateToken(header);

    updateUser({ token: header });
}

export const registerSecurity = (container) => {
    container.requestMiddleware = register(construct(requestTokenMiddleware), 'userStore');
    container.responseMiddleware = register(construct(responseTokenMiddleware), 'userStore', 'updateUser', 'navigateLogin');

    container.updateUser = instance(updateUser);
    container.getUser = instance(getUser);
}