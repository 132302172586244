import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import * as styles from './PhotoConfirmScreen.module.scss';
import { DefaultLayout } from "../../../../../Layouts/DefaultLayout";
// import { StampStatus } from "../PhotoCreateScreen/PhotoCreateScreen";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "./Gallery.scss";

export const PhotoConfirmScreen = ({ images, imageIndex, confirm, cancel, actions = true, back, active = true, historyPopHandler, stampData, title }) => {
    const [zoomScale, setZoomScale] = useState(1);
    const imageViewRef = useRef(null);

    useEffect(() => {
        const unlisten = historyPopHandler(back ? back : cancel);

        return () => unlisten();
    }, []);

    const _renderImg = ({ original }, index) => {
        return (
            <TransformWrapper
                disablePadding={true}
                wheel={{ activationKeys: ['Control'] }}
                panning={{ disabled: zoomScale === 1 }}
                onTransformed={(ref, state) => setZoomScale(state.scale)}>
                <TransformComponent wrapperClass={styles.screen__wrapper} contentClass={styles.screen__wrapper}>
                    <div className={styles.screen__wrapper}>
                        <img src={original} className={styles.screen__image} />
                    </div>
                </TransformComponent>
            </TransformWrapper>
        )
    }

    const Layout = ({ children }) => !actions
        ? <DefaultLayout screenCn={styles.screen} back={back} title={title}>{children}</DefaultLayout>
        : <div className={styles.layout}>{children}</div>

    return <Layout>
        <ImageGallery
            ref={imageViewRef} items={images.map((url) => ({ original: url }))} startIndex={imageIndex}
            showPlayButton={false} showBullets={!actions && images.length > 1} infinite={false} showFullscreenButton={false} showNav={false}
            renderItem={(item) => {
                return _renderImg(item)
            }}
        />

        {actions && <div className={styles.btnPanel}>
            <button className={classNames(styles.btn)} onClick={confirm}>
                Принять
            </button>

            <button className={classNames(styles.btn, styles.greyBtn)} onClick={cancel}>
                Повторить
            </button>
        </div>}
    </Layout>
}