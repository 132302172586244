.subheader {
    display: flex;
    flex-direction: row;
}

.main {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & header {
        background-color: #fff;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
        padding: 5px 15px;

        & p {
            text-align: center;
            font-family: 'Roboto Condensed';
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    &__subheader {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    &__title {
        color: var(--mobile-app-accent-color);
    }

    &__version {
        color: rgba(85, 85, 85, 1);
    }

    &__block {
        background-color: #fff;
        margin-top: 20px;
        width: 100%;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
        padding: 15px 0;

        &>p {
            margin: 0 0 15px 15px;
            color: rgba(0, 0, 0, 0.22);
            font-family: 'Roboto';
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0;
            text-transform: uppercase;
        }
    }

    &__image {
        width: 52px;
    }
}

.download {
    margin: 0 5.5px;
}