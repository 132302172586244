export const WifiIcon = ({ color = "#555", width = 32 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        fill="none"
        viewBox="211 489 32 32"
    >
        <g
            className="bi bi-wifi"
            style={{
                fill: "#000",
            }}
        >
            <path
                d="M241.768 501.23a.97.97 0 0 0-.094-1.472A24.876 24.876 0 0 0 227 495a24.877 24.877 0 0 0-14.674 4.758.97.97 0 0 0-.096 1.472 1.04 1.04 0 0 0 1.336.1A22.899 22.899 0 0 1 227 497c5.014 0 9.654 1.604 13.432 4.328.41.296.98.26 1.336-.098Z"
                className="fills"
                style={{
                    fill: color,
                    fillOpacity: 1,
                }}
            />
            <path
                d="M237.458 505.542a.96.96 0 0 0 .291-.775.96.96 0 0 0-.417-.715A18.923 18.923 0 0 0 227 501c-3.81 0-7.36 1.12-10.332 3.052a.962.962 0 0 0-.126 1.49c.351.343.892.397 1.304.13A16.923 16.923 0 0 1 227 503a16.914 16.914 0 0 1 9.152 2.672c.412.264.96.216 1.306-.13Zm-4.366 4.366c.452-.452.37-1.21-.2-1.5A13.002 13.002 0 0 0 227 507c-2.12 0-4.124.508-5.892 1.408-.57.29-.652 1.048-.2 1.5l.03.03c.32.32.814.38 1.222.18A11.003 11.003 0 0 1 227 509c1.736 0 3.38.402 4.84 1.12.406.2.9.14 1.252-.212Zm-3.972 3.972c.392-.392.396-1.04-.08-1.32a4 4 0 0 0-4.08 0c-.476.28-.472.928-.08 1.32l1.412 1.412a1 1 0 0 0 1.414 0l1.414-1.412Z"
                className="fills"
                style={{
                    fill: color,
                    fillOpacity: 1,
                }}
            />
        </g>
    </svg>
)