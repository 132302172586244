export function validateQr(qr) {
    const linkRegex = /(?:(http|https|rtsp)):\/\/((([\w\.]+)\/){0,}(\w{13}))(.(.*)){0,}/g;

    if (linkRegex.test(qr?.text?.toLowerCase())) return true;

    return false;
}

export async function getInfo(qr) {
    const isDevelop = /.(stage|develop)\/(\w{13})/g.test(qr.text);
    const url = qr.text.split('?')[0].split('//')[1];
    const protocol = qr.text.split('?')[0].split('//')[0] + '//';
    let requestUrl;

    if (isDevelop) {
        const splitUrl = url.split('/');

        requestUrl = `${protocol}${splitUrl[0]}/api/check/${splitUrl[1]}`
    } else {
        const splitIdUrl = url.replace(/\//g, '').split('.');

        requestUrl = `${protocol}id.${[...splitIdUrl].splice(1).join('.')}/api/check/${splitIdUrl[0]}`
    }

    return fetch(requestUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => res.json())
        .then(res => {
            if (!res?.Info) return;

            const { StampCode, RangeCode, Stamp, RangeYear, ProducerCode, Number, Range, ProducerMnemo } = res.Info;

            return {
                type: StampCode,
                producer: ProducerCode,
                producerMnemo: ProducerMnemo,
                displayRange: Range,
                displayType: Stamp,
                number: Number,
                year: String(RangeYear).slice(-2),
                range: String(RangeCode).padStart(3, "0")
            };
        })
        .catch(e => console.log('Ошибка обработки qr-кода'))
}