export const UploadIcon = ({ color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        style={{
            WebkitPrintColorAdjust: ":exact",
        }}
        viewBox="1486 1510 24 24"
    >
        <g
            style={{
                fill: "#000",
            }}
        >
            <path
                d="M1498 1512.25a9.75 9.75 0 0 1 9.75 9.75 9.75 9.75 0 0 1-9.75 9.75 9.75 9.75 0 0 1-9.75-9.75 9.75 9.75 0 0 1 9.75-9.75Zm0 21.75c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12Zm-5.667-13.809a1.046 1.046 0 0 0 .712 1.809h2.705v4.5c0 .83.67 1.5 1.5 1.5h1.5c.83 0 1.5-.67 1.5-1.5v-4.5h2.705a1.046 1.046 0 0 0 .712-1.809l-5.02-4.683a.957.957 0 0 0-.647-.258.95.95 0 0 0-.647.258l-5.02 4.683Z"
                className="fills"
                style={{
                    fill: color,
                    fillOpacity: 1,
                }}
            />
        </g>
    </svg>
)