export const DownloadIcon = ({ width = 24, color = '#000', opacity = '0.42' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={1.33 * width}
        fill="none"
        viewBox="979.034 895 24 32"
    >
        <g
            className="bi bi-file-arrow-down"
            style={{
                fill: "#000",
            }}
        >
            <path
                d="M991.034 905a1 1 0 0 1 1 1v7.586l2.292-2.294a1.001 1.001 0 0 1 1.416 1.416l-4 4a.999.999 0 0 1-1.416 0l-4-4a1.001 1.001 0 0 1 1.416-1.416l2.292 2.294V906a1 1 0 0 1 1-1Z"
                className="fills"
                style={{
                    fill: color,
                    fillOpacity: opacity,
                }}
            />
            <path
                d="M983.034 895a4 4 0 0 0-4 4v24a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4v-24a4 4 0 0 0-4-4Zm0 2h16a2 2 0 0 1 2 2v24a2 2 0 0 1-2 2h-16a2 2 0 0 1-2-2v-24a2 2 0 0 1 2-2Z"
                className="fills"
                style={{
                    fill: color,
                    fillOpacity: opacity
                }}
            />
        </g>
    </svg>
)