import { construct, instance, overwrite, register } from '../../../../module/ui-kit/source/platform/Container';
import { DocumentPage } from '../Document/DocumentPage';

export const registerRemoval = (container) => {
    container.removalPage = register(construct(DocumentPage), 'navigateMain', 'insertRemoval', 'getRemoval', 'navigateRemovalJournal', 'history', 'historyPopHandler', 'workerMessage', 'installJournalState');
    container.removalCreateUrl = instance('/removal/create');
    container.navigateRemovalCreate = register((navigate, url) => (params) => navigate(url, params), 'navigate', 'removalCreateUrl');
    overwrite(container, 'getRoutes', (getRoutes, Page, url) => () =>
        [...getRoutes(), {
            url,
            element: <Page title='Снятие' addText='Добавьте зпу для снятия' />
        }], 'base', 'removalPage', 'removalCreateUrl')
}