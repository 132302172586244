.login {
    position: relative;
    background-color: #fff;
    padding: 30px;
    display: flex;
    justify-content: center;
    margin: auto 0;
    width: 100%;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);

    &__inner {
        max-width: 412px;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
    }

    &__version {
        color: rgba(0, 0, 0, 0.43);
        font-size: 0.9em;
        font-family: "Roboto Condensed";
        font-weight: 400;
    }

    &__loader {
        margin-right: 0.6em;
    }

    &__image {
        width: 120px;
        margin: 30px 0 10px;
    }

    &__input {
        font-family: 'Open Sans';
        width: 100%;
        height: 100%;
        border-radius: 3px;
        border: 0.9px solid rgba(0, 0, 0, 0.22144092862324907);
        padding: 0 0.95em 0 2.5em;
        font-size: 0.9em;
        outline: none;

        &Wrap {
            margin-top: 1.2em;
            width: 100%;
            height: 35px;
            position: relative;
        }

        &Icon {
            position: absolute;
            top: 9px;
            left: 17px;
            color: rgba(0, 0, 0, 0.43);
            font-family: 'Open Sans', 'Font Awesome 5 Free';
        }

        &::placeholder {
            font-weight: 400;
            word-spacing: 5px;
            color: rgba(0, 0, 0, 0.22);
        }
    }

    &__button {
        cursor: pointer;
        margin-top: 20px;
        border: none;
        width: 100%;
        height: 45px;
        border-radius: 4px;
        background-color: var(--mobile-app-accent-color);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        outline: none;

        color: rgba(255, 255, 255, 0.9);
        font-family: 'Open Sans';
        font-size: 1em;
        font-weight: 700;
        transition: filter 0.2s, text-shadow 0.2s;

        &:hover,
        &:active {
            text-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
            filter: brightness(90%);
        }
    }

    &__greenButton {
        background-color: #43A047;
    }

    &__error {
        margin-top: 20px;
        width: 100%;
        padding: 15px 10px;
        border-radius: 4px;
        border: 2px solid var(--mobile-app-error-color);

        &>p {
            color: var(--mobile-app-error-color);
            font-family: 'Open Sans';
            font-weight: 600;
            font-size: 0.92em;
            text-align: center;
            white-space: pre-wrap;
            line-height: 12px;
        }
    }

    h1 {
        color: var(--mobile-app-accent-color);
        font-family: 'Open Sans';
        font-size: 1.5em;
        font-weight: 700;
        margin-bottom: 10px;
    }
}