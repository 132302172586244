import { getInfo, validateQr } from "../QrCodeValidate";

export const processBoxQr = async (qr, configurate = () => ({})) => {
    if (!validateQr(qr))
        return { qr, detail: new Promise(res => res({ code: qr.text, valid: false, message: "Некорректный формат qr-кода!" })) };

    const detail = new Promise((resolve) => {
        getInfo(qr).then((r) => {
            const defaultData = {
                type: "series",
                displayInfo: [`${r.displayRange} ${String(r.number).padStart(7, "0")}`, r.displayType, r.producerMnemo],
                playload: { type: r.type, producer: r.producer, range: r.range, date: r.year, begin: r.number, end: r.number }
            }

            resolve({
                ...Object.assign(defaultData, configurate?.(r)),
                code: qr.text,
                valid: true
            });
        }, (e) => {
            resolve({ code: qr.text, valid: false, repeat: true, message: "Не удалось загрузить информацию!" });
        });
    });

    return { qr, detail: detail, valid: true }
}