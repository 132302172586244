.loader {
  width: 25px;
  aspect-ratio: 1 / 1;
  flex: none;
  border: 3px solid rgba(47, 47, 47, 0.2);
  border-bottom-color: var(--uikit-accent-icon-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

