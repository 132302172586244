.screen {
  padding-bottom: 0;
}

.camera {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #000;

  &__loader {
    position: absolute;
    z-index: -1;
    width: 50px !important;
    height: 50px !important;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    color: #000;
  }

  &__qrWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 100px;
    min-width: 100px;

    &>section>div {
      padding-top: 0 !important;
    }

    &>section>div>video {
      position: relative !important;
    }
  }

  &__message {
    position: absolute;
    bottom: 130px;
    left: 30px;
    width: calc(100% - 60px);
    height: 50px;
    border-radius: 20px;
    background-color: rgba(166, 136, 0, 0.593);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;

    p {
      color: rgba(255, 255, 255, 0.9);
      font-family: 'Roboto';
      font-size: 1.5em;
      font-weight: 400;
      line-height: 1.2;
    }
  }

  &__errorMessage {
    background-color: rgba(183, 28, 28, 0.693);
  }

  &__rotate {
    position: absolute;
    bottom: 30px;
    cursor: pointer;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    padding: 8px;

    &>img {
      width: 100%;
    }
  }

}

.group {
  margin-bottom: 14px;

  &__time {
    margin: 0 0 0 0.7em;
    opacity: 0.7;
    font-size: 1.1em;
  }
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 15px;

  &__icon {
    font-size: 2.5em;
    margin: 0 auto 1.25em;
  }
}

@media screen and (max-width: 992px) {
  .container {
    padding: 0;
  }

  .camera {
    width: 100%;

    &__qrWrapper {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

@keyframes open {
  from {
    max-height: 64px;
  }

  to {
    max-height: calc(100dvh - 64px);
  }
}

@keyframes close {
  from {
    min-height: calc(100dvh - 64px);
  }

  to {
    min-height: 64px;
  }
}