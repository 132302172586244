import { construct, instance, register, registerSingleton } from "../../module/ui-kit/source/platform/Container";
import React from 'react';
import { Route, Routes } from 'react-router';
import { createBrowserHistory } from 'history';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

export const registerRouter = (container) => {
    container.history = registerSingleton(() => createBrowserHistory());
    container.getRoutes = instance(() => []);
    container.AppRouter = register(construct(AppRouter), 'getRoutes', 'history', 'toastContainer');
    container.navigate = register(construct((history, url, params) => {
        history.push(url, params)
    }), 'history');
    container.historyPopHandler = register(construct(historyPopHandler), 'history');
}

const historyPopHandler = (history, fn) => {
    return history.listen((update) => {
        if (update.action === 'POP' && fn) fn();
    });
}

const AppRouter = (getRoutes, history, ToastContainer) => {
    const routes = getRoutes();

    return (
        <HistoryRouter history={history}>
            <Routes>
                {routes.map(({ url, element }, index) => <Route key={index} path={url} element={element} />)}
            </Routes>
            <ToastContainer />
        </HistoryRouter>
    );
}