import { FaIcon } from '../../../../../../module/ui-kit/source/main/component/fa-icon';
import classNames from 'classnames';
import * as styles from './ConfirmScreen.module.scss';
import { useEffect, useState } from 'react';
import { PhotoConfirmScreen } from '../PhotoScreen/PhotoConfirmScreen/PhotoConfirmScreen';
import { DefaultLayout } from '../../../../Layouts/DefaultLayout';

const StampDataItem = ({ title, icon, Content }) => {
    return <div className={styles.item}>
        <div className={styles.item__title}>
            <FaIcon cn={styles.item__icon} icon={icon.name} color={icon.color || "#929292"} />
            <p>{title}</p>
        </div>
        {Content}
    </div>
}

export const ConfirmScreen = ({ onConfirm, onCancel, stampInstall, addImage, removeImage, active = true, back, historyPopHandler, title }) => {
    const { images, data } = stampInstall;
    const [imageIndex, setImageIndex] = useState(null);

    useEffect(() => {
        if (!images[imageIndex]) {
            const unlisten = historyPopHandler(back ? back : onCancel);

            return () => unlisten();
        }
    }, [imageIndex]);

    if (images[imageIndex]) {
        return <PhotoConfirmScreen title={data?.number && data.range ? `${data.range} ${data.number}` : 'Просмотр'} historyPopHandler={historyPopHandler}
            images={images} imageIndex={imageIndex} actions={false} active={active} back={() => setImageIndex(null)} />
    }

    return <DefaultLayout back={back} title={!active ? data?.number && data.range ? `${data.range} ${data.number}` : 'Просмотр' : title}>
        <div className={styles.screen__block}>
            <p>О ЗПУ</p>
            <StampDataItem title="QR-Код" icon={{ name: "fa-qrcode" }} Content={<FaIcon cn={styles.item__icon} icon="fa-check-circle" type='regular' color='#43A047' />} />
            <StampDataItem title="ТИП" icon={{ name: "fa-lock" }} Content={<p>{data?.type || 'Н/д'}</p>} />
            <StampDataItem title="НОМЕР" icon={{ name: "fa-lock" }} Content={<p>{data?.number && data.range ? `${data.range}${data.number}` : 'Н/д'}</p>} />
        </div>

        {images.map((url, index) => {
            return <div key={index} className={styles.screen__imageContainer}>
                <img src={url} onClick={() => setImageIndex(index)} />
                {active && <div className={styles.screen__imageFooter}>
                    <p className={styles.screen__imageRemove} onClick={() => removeImage(url)}>Удалить</p>
                </div>}
            </div>
        })}

        {active && <>
            {images.length < 3 && <p className={styles.screen__imageAdd} onClick={addImage}>Добавить фотографию</p>}

            <div className={styles.btnPanel}>
                <button className={classNames(styles.btn)} onClick={onConfirm}>
                    Принять
                </button>

                <button className={classNames(styles.btn, styles.greyBtn)} onClick={onCancel}>
                    Отмена
                </button>
            </div>
        </>}
    </DefaultLayout>
}