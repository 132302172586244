export const RepeatIcon = ({ color = '#555', width = 45, opacity = 1 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={0.795 * width}
        fill="none"
        style={{
            WebkitPrintColorAdjust: "exact",
        }}
        viewBox="2172.592 1084.075 45 35.769"
    >
        <g
            className="bi bi-arrow-repeat"
            style={{
                fill: "#000",
            }}
        >
            <path
                d="M2205.398 1098.978h11.466a.73.73 0 0 1 .66.429.76.76 0 0 1-.1.794l-5.733 7.037a.727.727 0 0 1-.56.268.724.724 0 0 1-.56-.268l-5.733-7.037a.76.76 0 0 1-.1-.794.729.729 0 0 1 .66-.429Zm-32.077 5.964h11.466c.283 0 .54-.168.66-.43a.759.759 0 0 0-.1-.793l-5.733-7.037a.722.722 0 0 0-1.12 0l-5.733 7.037a.759.759 0 0 0-.1.793c.12.262.377.43.66.43Z"
                className="fills"
                style={{
                    fill: color,
                    fillOpacity: opacity,
                }}
            />
            <path
                fillRule="evenodd"
                d="M2195.092 1087.051c-4.525 0-8.573 2.108-11.247 5.421a1.442 1.442 0 0 1-1.38.562 1.464 1.464 0 0 1-1.145-.966 1.515 1.515 0 0 1 .277-1.492c4.36-5.398 11.398-7.701 18.013-5.893 6.615 1.807 11.591 7.393 12.737 14.295h-2.966c-1.386-6.942-7.359-11.928-14.289-11.927Zm-14.288 17.891c1.126 5.626 5.313 10.092 10.763 11.48 5.45 1.389 11.196-.546 14.773-4.974a1.44 1.44 0 0 1 1.38-.562c.525.084.965.454 1.145.966a1.515 1.515 0 0 1-.277 1.492c-4.36 5.398-11.398 7.701-18.013 5.893-6.615-1.807-11.591-7.393-12.737-14.295Z"
                className="fills"
                style={{
                    fill: color,
                    fillOpacity: opacity,
                }}
            />
        </g>
    </svg>
)
