export const store = (getUser, updateUser) => {
    const Store = {
        user: null,
        token: null,
        onChange: () => null,
        subscribes: {},
        update: (user, token) => {
            Store.user = user;

            if (token) Store.token = token;

            updateUser({ ...user, token });

            Object.values(Store.subscribes).forEach(func => func());
        },
        updateToken: (token) => {
            Store.token = token;
        },
        subscribe: (onChange, name, raise) => {
            const firstSubscribe = !Store.subscribes[name];
            Store.subscribes[name] = onChange;
            if (raise && firstSubscribe) onChange();
        },
    }

    getUser().then(userData => {
        if (userData) {
            const { token, ...user } = userData;

            Store.update(user, token);
        } else {
            Store.update(Store.user)
        }
    })

    return Store;
};