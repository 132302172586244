import { useEffect, useState } from "react"
import { PhotoScreen } from "./PhotoScreen/PhotoScreen";
import { ScanScreen } from "./ScanScreen/ScanScreen";
import { ConfirmScreen } from "./ConfirmScreen/ConfirmScreen";
import { processBoxQr } from "../../../../../module/ui-kit/source/platform/form/extra/scaner/process/box-qrcode";

export const StampScreen = (props) => {
    const {
        onAddStamp, back, active = true, history, historyPopHandler, title,
        screen: startScreen = 'scan', stampInstall: startStampInstall
    } = props;

    const [screen, setScreen] = useState(startScreen);
    const [stampInstall, setStampInstall] = useState(startStampInstall);
    const [stampLoading, setStampLoading] = useState(false);

    useEffect(() => {
        history.push(`#${screen}`);
    }, [screen]);

    useEffect(() => {
        if (!active && stampInstall?.code && !stampInstall.data?.number) {
            setStampLoading(true)
            processBoxQr(
                { text: stampInstall?.code },
                (r) => ({
                    playload: {
                        number: String(r.number).padStart(7, '0'), range: r.displayRange, type: r.displayType
                    }
                }))
                .then(data => {
                    if (data.detail) {
                        return data.detail;
                    }
                })
                .then(detail => {
                    setStampInstall(prev => ({ ...prev, data: { ...detail.playload } }))
                })
                .finally(() => {
                    setStampLoading(false)
                })
        }
    }, [])

    const onScan = (code) => {
        setStampInstall({ code });
        setScreen('photo');
        setStampLoading(true)

        processBoxQr(
            { text: code },
            (r) => ({
                playload: {
                    number: String(r.number).padStart(7, '0'), range: r.displayRange, type: r.displayType
                }
            }))
            .then(data => {
                if (data.detail) {
                    return data.detail;
                }
            })
            .then(detail => {
                setStampInstall(prev => ({ ...prev, data: { ...detail.playload } }))
            })
            .finally(() => {
                setStampLoading(false)
            })
    }
    const onPhoto = (image) => {
        if (stampInstall) {
            setStampInstall(prev => ({ ...prev, images: prev.images ? [...prev.images, image] : [image] }));
            setScreen('confirm');
        }
    }
    const onConfirm = () => {
        onAddStamp(stampInstall);
        setScreen('scan');
    }
    const onCancel = () => {
        setScreen('scan');
    }
    const addImage = () => {
        if (stampInstall?.images?.length < 3) setScreen('photo');
    }
    const removeImage = (imageUrl) => {
        setStampInstall(prev => ({ ...prev, images: prev.images.filter(url => url !== imageUrl) }));
    }

    if (screen === 'scan') {
        return <ScanScreen historyPopHandler={historyPopHandler} onScan={onScan} back={back} title={title} />
    }

    if (screen === 'photo') {
        const stampData = {
            loading: stampLoading,
            text: stampInstall?.data?.number ? `${stampInstall.data.range || ''} ${stampInstall.data.number}` : 'Не удалось получить данные'
        }
        return <PhotoScreen title={title}
            stampData={stampData} historyPopHandler={historyPopHandler} onPhoto={onPhoto}
            back={() => setScreen(stampInstall?.images ? 'confirm' : 'scan')} />
    }

    return <ConfirmScreen title={title}
        historyPopHandler={historyPopHandler} back={!active && back} active={active} onConfirm={onConfirm}
        stampInstall={stampInstall} onCancel={onCancel} addImage={addImage} removeImage={removeImage} />
}
