import { useEffect, useState } from "react";
import { StampListScreen } from "./StampListScreen/StampListScreen";
import { StampScreen } from "./StampScreen/StampScreen";
import { useLocation } from "react-router";

export const defineCoodrinates = (props = {}) => new Promise(res => {
    const {
        requestPrivilegesMessage = "Требуется разрешение определения геоданных", requestPrivilegesCount = 3
    } = props;

    const locationCheck = ({ warningsQty = requestPrivilegesCount }) => {
        if (warningsQty >= requestPrivilegesCount) return;

        const changeLocation = (position) => {
            res({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            })
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(changeLocation, showError);
        }

        function showError(error) {
            console.log("Ошибка определения геоданных", error);

            if (error.code == error.PERMISSION_DENIED) {
                alert(requestPrivilegesMessage);
                alertTimeout = setTimeout(() => locationCheck({ warningsQty: warningsQty + 1 }), 5000);
                return;
            }

            alert("Ошибка определения геоданных");
            alertTimeout = setTimeout(() => locationCheck({ warningsQty: warningsQty + 1 }), 5000);
        }
    }

    navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
            permissionStatus.onchange = () => {
                locationCheck({ warningsQty: requestPrivilegesCount - 1 })
            };
        });

    locationCheck({ warningsQty: 1 });
})

export const DocumentPage = (navigateMain, insertDocument, getDocument, navigateInstallJournal, history, historyPopHandler, workerMessage, installJournalState, { title, addText }) => {
    const [list, setList] = useState(true);
    const [document, setDocument] = useState({ date: new Date(), stamps: [] });
    const [location] = useState(useLocation());

    const onAddStamp = (stamp, index) => {
        setDocument(prev => ({
            ...prev, stamps: prev.stamps[index]
                ? prev.stamps.map((st, i) => index === i ? stamp : st)
                : [...prev.stamps, stamp]
        }))
        setList(true);
    }

    const onConfirm = async () => {
        let resultDocument = document;

        document.stamps.forEach(async (stamp, index) => {
            await Promise.all(stamp.images.map((url) => fetch(url)))
                .then(async (responses) =>
                    await Promise.all(responses.map(async (res) => {
                        const blob = await res.blob();
                        const buffer = await blob.arrayBuffer();
                        return buffer
                    }))
                ).then(async (images) => {
                    resultDocument.stamps[index].images = images;
                })
        })

        insertDocument({ document: resultDocument });
        navigateMain();
    }
    const back = () => setList(true);
    const add = () => setList(false);
    const deleteStamp = (index) => {
        setDocument(prev => ({
            ...prev, stamps: prev.stamps.filter((stamp, i) => {
                return i !== index
            })
        }));
    }

    useEffect(() => {
        if (location.state?.id) {
            installJournalState.subscribe(() => {
                getDocument({ id: location.state.id }).then((document) => {
                    setDocument({
                        ...document, stamps: document.stamps.map(stamp => ({
                            ...stamp, images: stamp.images.map(image => {
                                if (image.startsWith?.('blob'))
                                    return image

                                const arrayBufferView = new Uint8Array(image);
                                const blob = new Blob([arrayBufferView], { type: "image/jpeg" });
                                return URL.createObjectURL(blob);
                            })
                        }))
                    })
                });
            }, 'installPage')
        } else {
            defineCoodrinates().then((coordinates) => {
                setDocument(doc => ({ ...doc, coordinates }))
            })
        }
    }, [location.state?.id]);

    return list
        ? <StampListScreen
            title={title} addText={addText}
            history={history} historyPopHandler={historyPopHandler} synchronize={() => location.state?.id ? workerMessage({ id: location.state.id }) : null}
            document={document} add={add} deleteStamp={deleteStamp} back={() => navigateMain()} onConfirm={onConfirm}
            active={!location.state?.id} navigateJornal={() => navigateInstallJournal()} onAddStamp={onAddStamp} />
        : <StampScreen
            title={title}
            history={history} historyPopHandler={historyPopHandler}
            onAddStamp={onAddStamp} back={back} active={!location.state?.id} />
}