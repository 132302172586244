.wrapper {
    background-color: #fff;
    padding: 0.4em 0;

    &:hover,
    &:active {
        background-color: #ececec;
    }
}

.item,
.subItem {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;

    &>p {
        margin: 0.3em 0.6em;
        text-transform: uppercase;
        font-family: 'Roboto Condensed';
        font-size: 1em;
        font-weight: 400;
        color: rgba(85, 85, 85, 1);
    }

    &__icon {
        font-size: 1em;
        min-width: 2.2em;
    }

    &__rightIcon {
        font-size: 1em;
        margin-left: auto;
        min-width: 2.2em;
    }

    transition: background-color 0.2s;
}

.subItem {
    min-width: 100%;

    &__rightIcon {
        min-width: 2.2em;
    }
}