import React from "react";
import { DefaultLayout } from "../../../Layouts/DefaultLayout";
import { Item } from "./Item/Item";
import * as styles from './ListScreen.module.scss';
import { FaIcon } from "../../../../../module/ui-kit/source/main/component/fa-icon";

export const ListScreen = ({ documents, viewDocument, navigateBack, refresh }) => {
    const days = documents
        .sort((a, b) => b.date - a.date)
        .reduce((arr, document, index, documents) => {
            const month = document.date.getMonth();
            const day = document.date.getDate();
            const dateString = document.date.toLocaleString('ru', {
                month: 'long',
                day: 'numeric',
            })

            if (index === 0 || documents[0].date - document.date < 604800000) {
                const group = arr.find((group) => group.month == month && group.day == day);

                if (group) {
                    group.documents.push(document);
                } else {
                    arr.push({
                        month, day, dateString, documents: [document]
                    })
                }
            }

            return arr;
        }, [])

    return <DefaultLayout back={navigateBack} screenCn={styles.screen} title="Журнал установки ЗПУ">
        {
            days.map(({ dateString, documents }, index) => {
                return <React.Fragment key={index}>
                    <p className={styles.screen__date}>{dateString}</p>

                    {
                        documents.map(({ stamps, id, documentNumber, error, synchronized, date, loading }, index) => {
                            const time = String(date.getHours()).padStart(2, '0') + ":" + String(date.getMinutes()).padStart(2, '0');

                            return <Item key={index} loading={loading} handler={() => viewDocument(id)} title={synchronized ? documentNumber : `ЛУСТ-${id}`} synchronized={synchronized} error={error} time={time} stamps={stamps} />
                        })
                    }
                </React.Fragment>
            })
        }

        <button className={styles.screen__button} onClick={refresh}>
            <FaIcon icon="fa-refresh" cn={styles.screen__buttonIcon} />
        </button>
    </DefaultLayout>
}