.screen {
    background-color: rgba(255, 255, 255, 1);

    &__date {
        width: 100%;
        padding: 15px 20px 10px;

        color: rgba(0, 0, 0, 0.22);
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &__button {
        cursor: pointer;
        position: fixed;
        width: 70px;
        height: 70px;
        bottom: 19px;
        right: 19px;
        border-radius: 50px;
        background-color: var(--mobile-app-accent-color);
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        transition: filter 0.2s, box-shadow 0.2s;

        &:active,
        &:hover {
            box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
            filter: brightness(90%);
        }

        &Icon {
            font-size: 24px;
            color: #fff;
        }
    }
}