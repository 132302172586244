import { register, construct, instance, overwrite } from "../../../../module/ui-kit/source/platform/Container";
import { MainPage, MainPageFooter } from "./MainPage";

export const registerMain = (container) => {
    container.mainPageFooter = register(construct(MainPageFooter), 'userStore', 'navigateLogin', 'installData')
    container.mainPage = register(construct(MainPage),
        'navigateInstallCreate', 'navigateRemovalCreate', 'navigateInstallJournal', 'navigateRemovalJournal', 'workerMessage',
        'mainPageFooter', 'installJournalState', 'removalJournalState', 'getInstallJournal', 'getRemovalJournal', 'version');
    container.mainUrl = instance("/");
    container.navigateMain = register((navigate, url) => (params) => navigate(url, params), 'navigate', 'mainUrl');
    overwrite(container, 'getRoutes', (getRoutes, Page, url) => () => [...getRoutes(), { url, element: <Page /> }], 'base', 'mainPage', 'mainUrl')
}