export const FolderIcon = ({ color = "#000", opacity = 0.22 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={128}
    height={128}
    fill="none"
    style={{
      WebkitPrintColorAdjust: "exact",
    }}
    viewBox="1616.068 644 128 128"
  >
    <g
      style={{
        fill: "#000",
      }}
    >
      <path
        d="M1744.068 748c0 8.825-7.175 16-16 16h-96c-8.825 0-16-7.175-16-16v-80c0-8.825 7.175-16 16-16h32c5.025 0 9.775 2.375 12.8 6.4l4.8 6.4a7.945 7.945 0 0 0 6.4 3.2h40c8.825 0 16 7.175 16 16v64Zm-70-10c0 3.325 2.675 6 6 6s6-2.675 6-6v-16h16c3.325 0 6-2.675 6-6s-2.675-6-6-6h-16v-16c0-3.325-2.675-6-6-6s-6 2.675-6 6v16h-16c-3.325 0-6 2.675-6 6s2.675 6 6 6h16v16Z"
        className="fills"
        style={{
          fill: color,
          fillOpacity: opacity,
        }}
      />
    </g>
  </svg>
)