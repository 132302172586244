import './Scss/Index.scss';
import { construct, instance, invoke, register, registerSingleton } from '../../module/ui-kit/source/platform/Container';
import { buildContainer } from './BuildContainer';
import 'react-toastify/dist/ReactToastify.css';

export const container = {};

buildContainer(container);

container.messageChannel = registerSingleton(() => new BroadcastChannel('sw-message'));
container.workerMessage = register(construct((messageChannel, { type = 'install', ...options } = {}) => {
    messageChannel.postMessage?.({ type, ...options })
}), 'messageChannel');

invoke(container, 'onStart')

let worker;
function setWebWorker() {
    if (!worker) {
        worker = new Worker(new URL('WebWorker.js', import.meta.url), { type: 'module' });
    }
}
setWebWorker();
setInterval(setWebWorker, 10000)

window.addEventListener('registerWorker', () => {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        const workers = registrations.filter(registration => registration?.active?.scriptURL.includes(location.host))
        workers.forEach((registration, index) => {
            registration.update();
        })

        if (!workers?.length) {
            navigator.serviceWorker.register(
                new URL('ServiceWorker.js', import.meta.url),
                { type: 'module' }
            )
        }
    })
})

navigator.serviceWorker.register(
    new URL('ServiceWorker.js', import.meta.url),
    { type: 'module' }
);