import md5 from "md5";
import { construct, instance, register } from "../../module/ui-kit/source/platform/Container";

export const Api = async (apiUrl, requestMiddleware, responseMiddleware, { msg, ActionName, method = 'POST', Params, body, headers, json = true }) => {
    try {
        const requestParams = {
            method,
            mode: "cors",
            headers: headers ? headers : {
                "Content-Type": "application/json"
            }
        }
        const response = await requestMiddleware({
            msg, apiUrl, method, Params, body,
            params: requestParams,
            request: async (request) => await fetch(`${apiUrl}${msg}`, method === 'GET'
                ? request
                : {
                    ...request,
                    body: body ? body : JSON.stringify({
                        ActionName,
                        Params: {
                            ...Params,
                            dtm: new Date()
                        }
                    })
                })
        })

        responseMiddleware({ response, msg, apiUrl, Params, body, method });

        if (!json) return { response };

        const data = await response.clone().json();

        return { response, data };
    } catch (err) {
        return console.log(err, 'api error')
    }
};

const loginApi = (Api, { login, password }) => {
    return Api({
        msg: "/api/security", ActionName: "login", Params: {
            Name: login,
            Pass: md5(password)
        }
    })
}

export const registerApi = (container) => {
    container.apiUrl = instance('');
    container.api = register(construct(Api), 'apiUrl', 'requestMiddleware', 'responseMiddleware');
    container.loginApi = register(construct(loginApi), 'api');
}
