export const register = (value, ...deps) => ({ constructor: (...deps) => value(...deps), deps, lifetime: '' });

export const registerSingleton = (value, ...deps) => ({
    constructor: (...deps) => value(...deps),
    deps,
    lifetime: 'singleton'
});

export const registerPerRequest = (value, ...deps) => ({
    constructor: (...deps) => value(...deps),
    deps,
    lifetime: 'request'
});

export const get = (container, name) => {
    const requestId = Math.random();

    container[requestId] = {};

    const result = getInternal(container, name, [], requestId);

    container[requestId] = null;

    return result
}


const getInternal = async (container, param, parents = [], requestId) => {
    const name = param.includes('-') ? param.split('-')[1] : param;

    if (!container[name]) throw new Error(`Не удалось разрешить зависимость: ${[...parents, name].reverse().join(' - ')}`);

    const { constructor, deps, lifetime } = container[param];
    let result;

    if (deps.length) {
        const resolvedDeps = deps.map(dep => {


            return (container[`${name}_${dep}`])
                ? getInternal(container, `${name}_${dep}`, [...parents, name], requestId)
                : getInternal(container, dep, [...parents, name], requestId)
        });

        await Promise.all(resolvedDeps).then(arr => {
            result = constructor(...arr);
        });
    } else {
        result = await constructor();
    }

    if (lifetime === 'request') {
        if (!container[requestId]) {
            container[requestId] = { [name]: { ...container[name], lifetime: 'singleton' } };
        } else if (!container[requestId][name]) {
            container[requestId][name] = { ...container[name], lifetime: 'singleton' };
        }

        if (!container[requestId][name]?.instance) {
            container[requestId][name].instance = result;
        } else {
            return container[requestId][name].instance;
        }
    }
    if (lifetime === 'singleton') {
        if (!container[name].instance) {
            container[name].instance = result;
        } else {
            return container[name].instance;
        }
    }

    return result;
};

export const overwrite = (container, name, func, ...deps) => {
    const random = Math.random();
    const base = !!container[name];
    container[`${random}-${name}`] = container[name];

    container[name] = deps.includes('base')
        ? register(async (...props) => !base
            ? func(() => { }, ...props)
            : func(...props), ...deps.filter((elem) => elem !== 'base' || base).map((elem) => elem === 'base' ? `${random}-${name}` : elem))
        : register(async (...props) => func(...props), ...deps);
}

export const invoke = async (container, name, id) => {
    get(container, name, [], id).then(func => func());
};

export const instance = (value) => ({ constructor: () => value, deps: [], lifetime: '' });

export const construct = (func) => {
    return (...deps) => {
        return (...props) => func(...deps, ...props);
    }
}