import { FaIcon } from "../../../../../module/ui-kit/source/main/component/fa-icon/FaIcon";
import * as styles from './Item.module.scss';

export const Item = ({ title, icon, statusIcon, handler, color, childs }) => {
    return <div className={styles.wrapper}>
        <div className={styles.item} onClick={handler}>
            {icon?.name ? <FaIcon cn={styles.item__icon} icon={icon.name} color={icon.color || "#929292"} /> : icon}
            <p style={color ? { color } : {}}>{title}</p>
            <FaIcon cn={styles.item__rightIcon} icon={statusIcon?.name || 'fa-angle-right'} color={statusIcon?.color || "#555555"} type={statusIcon?.type} size='lg' />
        </div>

        {childs?.length &&
            childs.map(({ title, icon, color }, index) => <div className={styles.subItem} key={index}>
                <div className={styles.item__icon} />
                <p style={color ? { color } : {}}>
                    {title}
                </p>
                {icon && <FaIcon cn={styles.item__rightIcon} icon={icon.name} color={icon.color || color || "#555555"} size='lg' />}
            </div>)}
    </div>
}