export const ClockIcon = ({ color = "#000", opacity = 0.43, width = 14 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        fill="none"
        style={{
            WebkitPrintColorAdjust: "exact",
        }}
        viewBox="1522 1524.5 14 14"
    >
        <path
            d="M1534.688 1531.5a5.689 5.689 0 1 1-11.377-.001 5.689 5.689 0 0 1 11.377.001Zm-12.688 0a7 7 0 1 0 14 0 7 7 0 0 0-14 0Zm6.344-3.719v3.719c0 .219.109.424.292.547l2.625 1.75c.301.202.709.12.911-.183a.655.655 0 0 0-.183-.911l-2.333-1.553v-3.369a.654.654 0 0 0-.656-.656.654.654 0 0 0-.656.656Z"
            className="fills"
            style={{
                fill: color,
                fillOpacity: opacity
            }}
        />
    </svg>
)