import * as styles from './MainPage.module.scss';
import { Item } from './Item/Item.jsx';
import React, { useEffect, useState } from 'react';
import { DownloadIcon, WifiIcon, WifiSlashIcon } from '../../Assets/SvgIcons';

export const MainPageFooter = (userStore, navigateLogin, installData) => {
    const [name, setName] = useState(userStore.user?.name);
    const [installStore, setInstallStore] = useState(installData);

    const logout = () => {
        userStore.update(null);
        navigateLogin();
    }

    useEffect(() => {
        userStore.subscribe(() => {
            setName(userStore.user?.name);
        }, 'header')

        const handler = (e) => {
            setInstallStore({ ...e.detail });
        }

        window.addEventListener('getInstallStore', handler);
    }, [])

    const { status, handler } = installStore || {};

    return <div className={styles.main__block}>
        <p>{name}</p>
        {status && <div onClick={handler}><Item title='Установить приложение' icon={<div className={styles.download}>
            <DownloadIcon />
        </div>} /></div>}
        <Item title='Выйти' icon={{ name: "fa-user" }} handler={logout} />
    </div>
}

function OnlineIndicator() {
    const [online, setOnline] = useState(navigator.onLine);

    useEffect(() => {
        window.addEventListener('online', () => setOnline(true))
        window.addEventListener('offline', () => setOnline(false))

        return () => {
            window.removeEventListener('online', () => setOnline(true))
            window.removeEventListener('offline', () => setOnline(false))
        }
    }, [])

    return online ? <WifiIcon /> : <WifiSlashIcon />;
}

export const MainPage = (navigateInstallCreate, navigateRemovalCreate, navigateInstallJournal, navigateRemovalJournal, workerMessage,
    Footer, installJournalState, removalJournalState, getInstallJournal, getRemovalJournal, version) => {

    const [notSynchronizedInstall, setNotSynchronizedInstall] = useState(0);
    const [notSynchronizedRemoval, setNotSynchronizedRemoval] = useState(0);
    const [installErrors, setInstallErrors] = useState(0);
    const [removalErrors, setRemovalErrors] = useState(0);

    useEffect(() => {
        installJournalState.subscribe(() => getInstallJournal().then(documents => {
            setNotSynchronizedInstall(documents.filter(({ synchronized, error }) => !synchronized && error !== 'error').length)
            setInstallErrors(documents.filter(({ error }) => error == 'error').length)
        }), 'main')
        removalJournalState.subscribe(() => getRemovalJournal().then(documents => {
            setNotSynchronizedRemoval(documents.filter(({ synchronized, error }) => !synchronized && error !== 'error').length)
            setRemovalErrors(documents.filter(({ error }) => error == 'error').length)
        }), 'main')
    }, []);

    const syncInstallTitle = (number) => number
        ? `Требуется синхронизация ${number} документ${number > 1 ? 'ов' : 'а'}!`
        : 'Синхронизация не требуется'
    const syncInstallStatusIcon = (number) => number
        ? { name: 'fa-exclamation-triangle', color: '#FF6D00' }
        : { name: "fa-check-circle", color: '#43A047', type: 'regular' }

    const image = new URL('../../Assets/logo.jpg', import.meta.url);

    const SyncItem = ({ syncNumber, errors }) => {
        return <Item title={syncInstallTitle(syncNumber)} color={syncNumber && '#FF6D00'} icon={{ name: "fa-refresh" }}
            statusIcon={syncInstallStatusIcon(syncNumber)}
            childs={errors ? [{
                color: 'var(--mobile-app-error-text-color)',
                title: `Ошибка синхронизации ${errors} документ${errors > 1 ? 'ов' : 'а'}!`,
                icon: { name: 'fa-exclamation-triangle' }
            }] : null}
            handler={() => workerMessage({ response: true })} />
    }

    return <div className={styles.main}>
        <header>
            <img src={String(image)} className={styles.main__image} />
            <div className={styles.main__subheader}>
                <OnlineIndicator />
                <p className={styles.main__version}>{version}</p>
            </div>
        </header>

        <div className={styles.main__block}>
            <p>Установка</p>
            <Item title='Установить ЗПУ' icon={{ name: "fa-lock" }} handler={() => navigateInstallCreate()} />
            <Item title='Журнал Установки' icon={{ name: "fa-list-check" }} handler={() => navigateInstallJournal()} />
            <SyncItem syncNumber={notSynchronizedInstall} errors={installErrors} />
        </div>

        <div className={styles.main__block}>
            <p>Снятие</p>
            <Item title='Снять ЗПУ' icon={{ name: "fa-lock-open" }} handler={() => navigateRemovalCreate()} />
            <Item title='Журнал Снятия' icon={{ name: "fa-list-check" }} handler={() => navigateRemovalJournal()} />
            <SyncItem syncNumber={notSynchronizedRemoval} errors={removalErrors} />
        </div>

        <Footer />
    </div>
}