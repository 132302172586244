import classNames from 'classnames';

import * as styles from './DefaultListScreen.module.scss';
import { FaIcon } from '../../../../../../module/ui-kit/source/main/component/fa-icon';
import { DefaultLayout } from '../../../../Layouts/DefaultLayout';
import { memo, useEffect, useState } from 'react';
import { ClockIcon, RepeatIcon } from '../../../../Assets/SvgIcons';

export const DefaultListScreen = memo(({ document, delete: deleteStamp, add, viewStamp, onCancel, onConfirm, active, navigateJornal, historyPopHandler, synchronize, title }) => {
    const { id, documentNumber, synchronized, stamps, date, synchronizedDate } = document;

    useEffect(() => {
        const unlisten = historyPopHandler(!active ? null : onCancel);

        return () => unlisten();
    }, []);

    const [error, setError] = useState(document.error);

    useEffect(() => {
        setError(document.error)
    }, [synchronizedDate]);

    const syncFn = () => {
        setError(false);
        synchronize();
    }

    const dateString = (date, seconds) => `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}${seconds ? `:${String(date.getSeconds()).padStart(2, '0')}` : ''}`;

    return <DefaultLayout back={!active && navigateJornal} title={!active ? synchronized ? documentNumber : `ЛУСТ-${id}` : title}>
        {error && <div className={styles.screen__error}>
            {synchronizedDate && <p className={styles.screen__errorDate}>{dateString(synchronizedDate, true)}</p>}
            <p>
                {error.text}
                <br />
            </p>
            <p className={styles.screen__errorSync} onClick={syncFn}>повторить отправку</p>
        </div>}

        {id && <div className={styles.screen__block}>
            <p>Информация</p>
            <div className={styles.screen__number}>
                <FaIcon cn={styles.screen__numberIcon} type='regular' color='#000' icon='fa-file' />
                <p className={styles.screen__numberTitle}>Документ</p>
                <p>{synchronized ? documentNumber : `ЛУСТ-${id}`}</p>
            </div>

            <div className={styles.screen__number}>
                <div className={styles.screen__icon}><ClockIcon width={24} /></div>
                <p className={styles.screen__numberTitle}>{title}</p>
                <p>{dateString(date)}</p>
            </div>

            <div className={styles.screen__number}>
                <div className={styles.screen__icon}><RepeatIcon width={26} color={'#000'} opacity={0.43} /></div>
                <p className={styles.screen__numberTitle}>Синхронизация</p>
                <p>{synchronizedDate ? dateString(synchronizedDate) : 'Н/Д'}</p>
            </div>
        </div>}

        {stamps.map(({ images, data }, index) => {
            const removeStamp = (e) => {
                e.stopPropagation();
                deleteStamp(index)
            }

            return <div key={index} className={styles.screen__imageContainer} onClick={() => viewStamp(index)}>
                <div className={styles.screen__imageHeader}>
                    {data?.number && data?.type
                        ? <>
                            <p>{data.range}{data.number}</p>
                            <p>{data.type}</p>
                        </>
                        : <FaIcon color="#555555" icon='fa-qrcode' />}
                </div>
                <img src={images[0]} />
                <div className={styles.screen__imageFooter}>
                    <div className={styles.screen__imageFooterQuantity}>
                        <FaIcon color="#555555" icon='fa-camera' />
                        <p>{images.length}</p>
                    </div>
                    {active && <p className={styles.screen__imageRemove} onClick={removeStamp}>Удалить</p>}
                </div>
            </div>
        })}

        {active && <>
            <p className={styles.screen__imageAdd} onClick={add}>Добавить ЗПУ</p>

            <div className={styles.btnPanel}>
                <button className={classNames(styles.btn)} onClick={onConfirm}>
                    Сохранить
                </button>

                <button className={classNames(styles.btn, styles.greyBtn)} onClick={onCancel}>
                    Отмена
                </button>
            </div>
        </>}
    </DefaultLayout>
}, (prev, next) => String(prev.document.synchronizedDate) == String(next.document.synchronizedDate) && prev.title == next.title &&
    prev.document.stamps?.length == next.document.stamps?.length)