.page {
    overflow-x: hidden;
    height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__header {
        width: 100%;
        height: 3.9em;
        background-color: var(--mobile-app-accent-color);
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
        display: flex;
        flex: none;
        align-items: center;
        justify-content: start;
        padding: 0 20px;

        &Icon {
            margin-left: auto;
            cursor: default !important;
        }

        &>div {
            cursor: pointer;
            margin-right: 20px;
        }

        &>p {
            color: rgba(255, 255, 255, 0.9);
            font-family: 'Open Sans';
            font-size: 24px;
            font-weight: 700;
        }
    }

    &__screen {
        height: calc(100dvh - 3.9em);
        overflow-x: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 1.2em;
    }
}