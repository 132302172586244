import { useEffect, useState } from 'react';
import { LeftIcon, WifiSlashIcon } from '../Assets/SvgIcons';
import * as styles from './DefaultLayout.module.scss';
import classNames from 'classnames';

export const DefaultLayout = ({ children, back, screenCn, title = 'Установка' }) => {
    const [online, setOnline] = useState(navigator.onLine);

    useEffect(() => {
        window.addEventListener('online', () => setOnline(true))
        window.addEventListener('offline', () => setOnline(false))

        return () => {
            window.removeEventListener('online', () => setOnline(true))
            window.removeEventListener('offline', () => setOnline(false))
        }
    }, [])

    return <div className={styles.page}>
        <div className={styles.page__header}>
            {back && <div onClick={() => back()}>
                <LeftIcon opacity={0.9} width="28.502" height="24.004" />
            </div>}
            <p>{title}</p>

            {!online && <div className={styles.page__headerIcon}><WifiSlashIcon color="#fff" /></div>}
        </div>
        <div className={classNames(styles.page__screen, screenCn)}>
            {children}
        </div>
    </div>
}