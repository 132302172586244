import { useState } from "react"
import { PhotoCreateScreen } from "./PhotoCreateScreen/PhotoCreateScreen"
import { PhotoConfirmScreen } from "./PhotoConfirmScreen/PhotoConfirmScreen";

export const PhotoScreen = ({ onPhoto, url, back, historyPopHandler, stampData, title }) => {
    const [image, setImage] = useState(url);

    const addImage = image => setImage(image);
    const cancel = () => setImage(null);
    const confirm = () => onPhoto(image);

    if (!image) {
        return <PhotoCreateScreen addImage={addImage} back={back} historyPopHandler={historyPopHandler} stampData={stampData} title={title} />
    }

    return <PhotoConfirmScreen images={[image]} imageIndex={0} confirm={confirm} historyPopHandler={historyPopHandler} cancel={cancel} stampData={stampData} title={title} />
}