import { openDB } from 'idb';
import { instance, register } from '../../module/ui-kit/source/platform/Container';

export const registerStore = (container) => {
    container.getRemovalJournal = instance(() => getJournal({ type: 'removal' }));
    container.getInstallJournal = instance(() => getJournal({ type: 'install' }));
    container.insertInstall = register((...deps) => ({ document }) => insertDocument(...deps, { document, type: 'install' }), 'workerMessage', 'installJournalState');
    container.insertRemoval = register((...deps) => ({ document }) => insertDocument(...deps, { document, type: 'removal' }), 'workerMessage', 'installJournalState');
    container.getInstall = instance(({ id }) => getDocument({ id, type: 'install' }));
    container.getRemoval = instance(({ id }) => getDocument({ id, type: 'removal' }));
}

const openStore = async () => await openDB("Usage", 6, {
    upgrade(db, oldVersion, newVersion, transaction) {
        if (oldVersion < 6 && newVersion >= 6) {
            if (!db.objectStoreNames.contains("document")) {
                db.createObjectStore("document");
            }
            if (!db.objectStoreNames.contains("user")) {
                db.createObjectStore("user");
            }

            if (oldVersion < 6) {
                if (db.objectStoreNames.contains('install')) {
                    db.deleteObjectStore('install');
                }
            }
        }
    }
})

export const insertDocument = async (workerMessage, installJournalState, { document, type }) => {
    const dbGetAction = await openStore();
    const storeGetAction = dbGetAction.transaction('document', 'readonly').objectStore('document');
    const keys = await storeGetAction.getAllKeys();

    dbGetAction.close();

    const bufferFn = async (url) => {
        const image = await fetch(url);
        const blob = await image.blob();
        return await blob.arrayBuffer();
    }

    let documentId = keys?.length ? String(Number(Math.max(...keys.map(documentId => Number(documentId)))) + 1).padStart(6, "0") : '000001';

    const stamps = await Promise.all(document.stamps.map(async ({ images, ...stamp }) => {
        const bufferImages = await Promise.all(images.map(async (url) => {
            if (typeof url !== 'string') return url;

            return await bufferFn(url);
        }))

        return { ...stamp, images: bufferImages }
    }))

    const db = await openStore();
    const store = db.transaction('document', 'readwrite').objectStore('document');
    await store.put({ ...document, type, stamps, synchronized: false }, documentId);
    installJournalState.update()
    workerMessage();
    db.close();
}

export const getJournal = async ({ type }) => {
    const db = await openStore();
    const store = db.transaction("document", 'readonly').objectStore("document");
    const keys = await store.getAllKeys();
    const values = await store.getAll();

    const result = values.reduce((arr, { date, stamps, synchronized, error, documentNumber, type: documentType }, index) => {
        //TODO почему syncDate тут генерится?
        if (documentType == type || !type) {
            return [...arr, {
                date, documentNumber, synchronized, error: error?.type, synchronizedDate: new Date(), stamps: stamps.length, id: keys[index]
            }]
        }

        return arr;
    }, [])

    db.close();

    return result;
}

export const getDocument = async ({ id, type }) => {
    const db = await openStore();
    const store = db.transaction("document", 'readwrite').objectStore("document");
    const document = await store.get(id);

    db.close();

    return { ...document, id }
}

export const getInstallToSync = async (type, id) => {
    const db = await openStore();
    const store = db.transaction('document', 'readonly').objectStore('document');
    const values = await store.getAll();
    const keys = await store.getAllKeys();

    const index = id
        ? keys.findIndex(key => key == id)
        : values.findIndex(({ synchronized, error }) => !synchronized && error?.type !== 'error');

    if (!values[index]) return null;

    db.close();

    return { ...values[index], id: keys[index] };
}

export const getInstallList = async (type, filter) => {
    const db = await openStore();
    const store = db.transaction('document', 'readonly').objectStore('document');
    const values = await store.getAll();
    const keys = await store.getAllKeys();

    db.close();

    return keys.map((id, index) => ({ ...values[index], id })).filter(filter);
}

export const updateInstall = async (type, id, document) => {
    const db = await openStore();
    const store = db.transaction('document', 'readwrite').objectStore('document');

    await store.put(document, id);
    db.close();
}

export const updateUser = async (user) => {
    const { id, token } = user || {};

    const db = await openStore();
    const store = db.transaction('user', 'readwrite').objectStore('user');
    const storeUser = await store.get('user');

    if (!user) {
        await store.put(null, 'user');
    } else {
        await store.put(id
            ? { ...user, token: token || storeUser.token }
            : token
                ? { ...storeUser || null, token }
                : null, 'user');
    }
    db.close();
}
export const getUser = async () => {
    const db = await openStore();
    const store = db.transaction('user', 'readonly').objectStore('user');

    const user = await store.get('user');

    db.close();
    return user;
}