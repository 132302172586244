const isMobile = navigator.userAgentData.mobile;
const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

export const installData = {
    status: isMobile && !isStandalone,
    handler: null
}

export const downloadListener = () => {
    let deferredPrompt;
    const clickHandler = () => {
        if (deferredPrompt) deferredPrompt.prompt().then(({ outcome }) => {
            if (outcome === 'accepted') {
                installData.status = false;
                installData.handler = () => null;

                window.dispatchEvent(new CustomEvent('getInstallStore', {
                    detail: installData
                }));
            }
        })
    }

    const handler = (e) => {
        installData.status = true;
        installData.handler = clickHandler;

        window.dispatchEvent(new CustomEvent('getInstallStore', {
            detail: installData
        }));

        e.preventDefault();
        deferredPrompt = e;
    }

    window.addEventListener('beforeinstallprompt', handler);
}