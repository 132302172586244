import React from 'react';
import ReactDOM from 'react-dom/client';
import { construct, instance, register, registerSingleton } from '../../module/ui-kit/source/platform/Container';
import { registerMain } from './Pages/MainPage/RegisterMain';
import { registerLogin } from './Pages/LoginPage/RegisterLogin';
import { registerInstall } from './Pages/InstallPage/RegisterInstall';
import { registerInstallJournal } from './Pages/InstallJournalPage/RegisterInstallJournal';
import { registerRouter } from './Router';
import { registerStore } from './Store';
import { registerApi } from './Api';
import { registerSecurity } from './Security';
import { store } from './UserStore';
import { registerToast } from './Toast';
import { registerRemoval } from './Pages/RemovalPage/RegisterRemoval';
import { registerRemovalJournal } from './Pages/RemovalJournalPage/RegisterRemovalJournal';
import { downloadListener, installData } from './Utils/Download';

const MountRouterOnStart = (AppRouter, id, channel, messageHandler, userStore, navigateLogin, loginUrl, downloadListener) => {
    channel.addEventListener('message', event => {
        messageHandler(event.data);
    });
    const root = ReactDOM.createRoot(document.getElementById(id));

    downloadListener();

    userStore.subscribe(() => {
        if (!userStore.token && window.location.pathname !== loginUrl) {
            navigateLogin();
        }
    }, 'login');

    root.render(<AppRouter />);
}
export const buildContainer = (container) => {
    container.version = instance('1.20')
    container.mountElementId = instance('root');
    container.appTitle = instance('АСПП ЭКСПЛУАТАЦИЯ');
    container.onStart = register(construct(MountRouterOnStart), 'AppRouter', 'mountElementId', 'messageChannel', 'messageHandler', 'userStore', 'navigateLogin', 'loginUrl', 'downloadListener');
    container.userStore = registerSingleton(store, 'getUser', 'updateUser');
    container.downloadListener = instance(downloadListener);
    container.installData = instance(installData);

    registerRouter(container);
    registerToast(container);
    registerLogin(container);
    registerMain(container);
    registerInstall(container);
    registerRemoval(container)
    registerRemovalJournal(container);
    registerInstallJournal(container);
    registerStore(container);
    registerApi(container);
    registerSecurity(container);
}