export const PlusIcon = ({ color = "#fff", opacity = 0.9 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={27.625}
        height={27.625}
        fill="none"
        style={{
            WebkitPrintColorAdjust: "exact",
        }}
        viewBox="1818.256 1090.188 27.625 27.625"
    >
        <path
            d="M1834.193 1092.313a2.122 2.122 0 0 0-2.125-2.125 2.123 2.123 0 0 0-2.125 2.125v9.562h-9.562c-1.176 0-2.125.95-2.125 2.125s.949 2.125 2.125 2.125h9.562v9.563c0 1.175.95 2.125 2.125 2.125 1.176 0 2.125-.95 2.125-2.125v-9.563h9.563c1.175 0 2.125-.95 2.125-2.125s-.95-2.125-2.125-2.125h-9.563v-9.562Z"
            className="fills"
            style={{
                fill: color,
                fillOpacity: opacity
            }}
        />
    </svg>
)