import * as styles from './LoginPage.module.scss';
import image from '../../Assets/logo.jpg';
import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import md5 from 'md5';
import { FaIcon } from '../../../../module/ui-kit/source/main/component/fa-icon';
import { LoaderIcon } from '../../../../module/ui-kit/source/main/component/loader-icon/LoaderIcon';

export const LoginPage = (loginHandler, version, installData) => {
    const [loading, setLoading] = useState(false);
    const [loginInput, setLoginInput] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [installStore, setInstallStore] = useState(installData);

    const loginRef = useRef(null);
    const passwordRef = useRef(null);

    const errorHandler = (text) => {
        setLoading(false);
        setError(text);
    }
    const loginFn = () => {
        setLoading(true);
        loginHandler(loginInput, password, errorHandler);
    }

    useEffect(() => {
        const handler = (e) => {
            setInstallStore({ ...e.detail });
        }

        window.addEventListener('getInstallStore', handler);
    }, [])

    const { status, handler } = installStore || {};


    return <div className={styles.login}>
        <div className={styles.login__inner}>
            <img src={image} className={styles.login__image} />
            <h1>АСПП Эксплуатация</h1>
            <p className={styles.login__version}>версия {version}</p>
            <div className={styles.login__inputWrap}>
                <FaIcon cn={styles.login__inputIcon} icon="fa-user" color='rgba(0, 0, 0, 0.43)' size='md' onClick={() => loginRef.current.focus()} />
                <input disabled={loading} ref={loginRef} className={styles.login__input} placeholder='Логин' value={loginInput} onChange={(e) => setLoginInput(e.target.value?.trim?.())} />
            </div>
            <div className={styles.login__inputWrap}>
                <FaIcon cn={styles.login__inputIcon} icon="fa-lock" color='rgba(0, 0, 0, 0.43)' size='md' onClick={() => passwordRef.current.focus()} />
                <input disabled={loading} ref={passwordRef} className={styles.login__input} placeholder='Пароль' type="password" value={password} onChange={(e) => setPassword(e.target.value?.trim?.())} />
            </div>
            {
                error && <div className={styles.login__error}>
                    <p>{error}</p>
                </div>
            }
            <button disabled={loading} className={styles.login__button} onClick={loginFn}>{loading && <LoaderIcon cn={styles.login__loader} />} Войти</button>
            {status && <button onClick={handler} className={classNames(styles.login__button, styles.login__greenButton)}>Установить</button>}
        </div>
    </div>
}

export const login = (loginApi, userStore, navigateMain, login, password, errorHandler) => {
    loginApi({ login, password })
        .then(({ data }) => {
            if (data.State == 'Success') {
                const { name, login } = data.user;

                userStore.update({ id: login, name, password: md5(password) });
                navigateMain();
            } else {
                errorHandler(data?.Reason || 'Возникла ошибка\n\nОбратитесь к администратору')
            }
        })
        .catch(e => {
            errorHandler(navigator.onLine ? 'Возникла ошибка\n\nОбратитесь к администратору' : 'Отсутствует интернет-соединение')
        });
}