import React from 'react';
import { Html5Qrcode } from 'html5-qrcode';

import { useEffect } from 'react';
import './Reader.scss';

const qrcodeRegionId = "uikit-QrReader";
let html5QrCode;

export const Reader = ({ deviceId, onScan, cameraFacing }) => {
    useEffect(() => {
        return () => {
            if (html5QrCode && html5QrCode.getState() === 2) {
                html5QrCode.stop().then(() => {
                    html5QrCode = null;
                })
            } else {
                html5QrCode = null;
            }
        };
    }, []);

    useEffect(() => {
        if (html5QrCode) {
            if (html5QrCode.getState() === 2) {
                html5QrCode.stop().then((ignore) => {
                    html5QrCode.start(
                        deviceId ? deviceId : {
                            facingMode: cameraFacing
                        },
                        {
                            fps: 10
                        },
                        (decodedText, decodedResult) => {
                            onScan(decodedResult);
                        })
                        .catch((err) => {
                            // Start failed, handle it.
                        });
                });
            }
        } else if (deviceId || cameraFacing) {
            html5QrCode = new Html5Qrcode(qrcodeRegionId);
            html5QrCode.start(
                deviceId ? deviceId : {
                    facingMode: cameraFacing
                },
                {
                    fps: 10
                },
                (decodedText, decodedResult) => {
                    onScan(decodedResult);
                })
        }
    }, [deviceId, cameraFacing]);

    return (
        <div id={qrcodeRegionId} />
    );
};