export const WifiSlashIcon = ({ color = "#000", width = 30.1 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-28.063 554.439 30.127 23.621"
        width={width}
        height={0.78 * width}
        fill="none"
    >
        <path
            d="M-7.588 557.588A25.187 25.187 0 0 0-13 557a24.877 24.877 0 0 0-14.674 4.758.97.97 0 0 0-.096 1.472 1.04 1.04 0 0 0 1.336.1A22.899 22.899 0 0 1-13 559c1.261 0 2.496.099 3.704.296ZM-13 563c-3.81 0-7.36 1.12-10.332 3.052a.962.962 0 0 0-.126 1.49c.351.343.892.397 1.304.13a16.907 16.907 0 0 1 7.02-2.54Zm5.192 2.808 1.57-1.57a18.896 18.896 0 0 1 3.57 1.814.96.96 0 0 1 .417.715.96.96 0 0 1-.291.775 1.049 1.049 0 0 1-1.304.13 16.98 16.98 0 0 0-3.962-1.864ZM-13 571l1.866-1.866c1.402.202 2.762.633 4.026 1.274.57.29.652 1.048.2 1.5l-.03.03a1.06 1.06 0 0 1-1.222.18A11.003 11.003 0 0 0-13 571Zm9.81-9.81 1.494-1.494a25.233 25.233 0 0 1 3.37 2.06.969.969 0 0 1 .094 1.474 1.04 1.04 0 0 1-1.336.1 23.012 23.012 0 0 0-3.622-2.14Zm-7.77 13.37c.476.28.472.928.08 1.32l-1.414 1.412a1 1 0 0 1-1.414 0l-1.414-1.414c-.39-.39-.394-1.036.08-1.32A3.998 3.998 0 0 1-13 574c.748 0 1.446.204 2.04.56Zm8.71-19.81a1.061 1.061 0 0 1 1.5 1.5l-21.5 21.5a1.061 1.061 0 0 1-1.5-1.5Z"
            className="fills"
            style={{
                fill: color
            }}
        />
    </svg>
)