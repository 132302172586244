import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { LoaderIcon } from "../../../../../../../module/ui-kit/source/main/component/loader-icon";
import { RepeatIcon } from "../../../../../Assets/SvgIcons";
import { FaIcon } from "../../../../../../../module/ui-kit/source/main/component/fa-icon/FaIcon";
import sounds from '../../../../../Assets/sounds.json';
import * as styles from './PhotoCreateScreen.module.scss';
import { DefaultLayout } from "../../../../../Layouts/DefaultLayout";

let errorTimeout;

function Warning({ children }) {
    return <div className={styles.warning}>
        <FaIcon icon={'fa-video-slash'} color="rgba(85, 85, 85, 1)" cn={styles.warning__icon} size="2xl" />
        <p>{children}</p>
    </div>;
}

function Message({ children, className }) {
    return <div className={classNames(styles.message, className)}>{children}</div>;
}

export const StampStatus = ({ stampData }) => stampData && <Message className={styles.stamp}>
    {stampData.loading
        ? <>
            <LoaderIcon cn={styles.stamp__loader} />
            <p>Поиск ЗПУ</p>
        </>
        : <p>{stampData.text}</p>}
</Message>

export const PhotoCreateScreen = ({ addImage, back, historyPopHandler, stampData, title }) => {
    const [error, setError] = useState(null);
    const [devices, setDevices] = useState([]);
    const [inited, setInited] = useState(false);
    const [videoPermission, setVideoPermission] = useState(false);
    const [cameraFacing, setCameraFacing] = useState("environment");
    const [loading, setLoading] = useState(true);
    const [canRotate, setCanRotate] = useState(true);

    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const audio = new Audio("data:audio/wav;base64," + sounds.snapshot);
    const playAudio = audio.play.bind(audio);

    useEffect(() => {
        clearTimeout(errorTimeout);

        const video = videoRef.current;

        const init = async () => {
            if (video && inited) {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            facingMode: cameraFacing
                        },
                        audio: false,
                    })

                    setLoading(false);
                    video.srcObject = stream;

                    return stream
                } catch {
                    setLoading(false);
                }
            }
        }

        let streamPromise;

        setTimeout(() => {
            streamPromise = init();
        })

        if (inited) {
            setError(null);

            errorTimeout = setTimeout(() => {
                const video = videoRef.current;
                if (!(video?.readyState > 2)) setError(prev => prev ? prev : 'Ошибка источника');
            }, 5000)
        } else {
            setTimeout(() => {
                setInited(prev => {
                    if (!prev) {
                        setError(prev => prev ? prev : 'Устройство не найдено')
                    }

                    return prev;
                })
            }, 5000)
        }

        return () => {
            if (streamPromise?.then) {
                setLoading(true);
                streamPromise.then(stream => {
                    const tracks = stream?.getTracks?.();
                    if (tracks) {
                        for (const track of tracks) {
                            track.enabled = false;
                            track.stop();
                            stream.removeTrack(track);
                        }
                    }
                })
            }
        }
    }, [inited, cameraFacing]);
    useEffect(() => {
        setTimeout(() => {
            navigator.permissions.query({ name: 'camera' }).then(function (permissionStatus) {

                if (permissionStatus.state === 'prompt') {
                    navigator.mediaDevices.getUserMedia(
                        { video: true, audio: false },
                        () => { },
                        (error) => console.log(error))
                        .then((stream) => {
                            const tracks = stream.getVideoTracks();

                            for (const track of tracks) {
                                track.enabled = false;
                                track.stop();
                                stream.removeTrack(track);
                            }
                        });
                } else if (permissionStatus.state === 'denied') {
                    setError('Нет разрешения на использование камеры. Проверьте настройки сайта.')
                }

                setVideoPermission(permissionStatus.state)

                permissionStatus.onchange = function () {
                    setVideoPermission(true)
                };
            });
        });

        const unlisten = historyPopHandler(back);

        return () => {
            unlisten();
            clearTimeout(errorTimeout);
        }
    }, [])
    useEffect(() => {
        if (videoPermission && navigator.mediaDevices?.enumerateDevices) {
            navigator.mediaDevices.enumerateDevices()
                .then(function (devices) {
                    const videoDevices = devices.filter(({ kind, deviceId }) => kind === 'videoinput' && !!deviceId);

                    if (!videoDevices?.length) {
                        if (!error) {
                            setError('Видеоустройства не обнаружены.');
                        }
                        return;
                    }

                    setError(null);
                    setDevices(videoDevices);
                    setInited(true);
                })
                .catch(function (err) {
                    console.error('err', err);
                });
        } else if (videoPermission) {
            setError('Ошибка получения списка устройств. Возможно, браузер устарел.');
        }
    }, [videoPermission]);

    const cameraRotate = () => {
        if (canRotate) {
            var video = videoRef.current;

            if (devices.length > 1 && (video?.readyState > 2 || error)) {
                setCameraFacing(prev => prev === 'user' ? 'environment' : 'user');
            }

            setCanRotate(false);
            setTimeout(() => setCanRotate(true), 1000);
        }
    }
    const capture = () => {
        var canvas = canvasRef.current;
        var video = videoRef.current;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext("2d")
            .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        canvas.toBlob(blob => {
            if (blob) {
                playAudio();

                addImage(URL.createObjectURL(blob));
            }
        }, "image/jpeg")
    }

    function CameraButtons() {
        return <div className={styles.cameraButtons}>
            <button onClick={capture} className={classNames(styles.btn, styles.captureBtn)} />
            {devices.length > 1 && <button onClick={cameraRotate} className={classNames(styles.btn, styles.rotateBtn)}>
                <RepeatIcon color="#fff" />
            </button>}
        </div>;
    }

    return <DefaultLayout back={back} screenCn={styles.screen} title={title}>

        {!error && <div className={styles.interactionLayer}>
            <div>
                <StampStatus stampData={stampData} />
            </div>
            <div>
                <Message>Сделайте фотографию</Message>
                <CameraButtons />
            </div>
        </div>}

        {error
            ?<Warning>{error}</Warning>
            : <>
                <video style={{ display: loading ? 'none' : 'block' }} autoPlay muted playsInline className={styles.video} ref={videoRef}></video>
                {loading && <div className={styles.warning}>
                    <LoaderIcon cn={styles.mainLoader} />
                </div>}
            </>}
        <canvas style={{ display: 'none' }} ref={canvasRef}></canvas>
    </DefaultLayout>
}