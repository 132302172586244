.screen {
    &__error {
        width: 100%;
        background-color: rgba(183, 28, 28, 0.6929408482142857);
        padding: 20px 20px;
        margin-top: 20px;

        color: rgba(255, 255, 255, 0.9);
        font-family: 'Roboto';
        font-size: 1.1em;
        font-weight: 400;
        text-align: center;
        word-break: break-word;
        white-space: pre-wrap;

        &Date {
            text-align: right;
            font-size: 0.9em;
            font-weight: 700;
            font-family: "Roboto Condensed";
            margin-bottom: 0.9em;
        }

        &Sync {
            display: block;
            text-transform: uppercase;
            text-decoration: underline;
            font-size: 1.1em;
            font-family: "Roboto Condensed";
            font-weight: 700;
            margin-top: 0.9em;
            cursor: pointer;
        }
    }

    &__block {
        background-color: #fff;
        margin: 1.2em 0 0;
        width: 100%;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
        padding: 0.95em 0 0.95em 0.35em;

        &>p {
            margin: 0 0 0.95em 0.95em;
            color: rgba(0, 0, 0, 0.22);
            font-family: 'Roboto';
            font-size: 0.8em;
            font-weight: 700;
            letter-spacing: 0;
            text-transform: uppercase;
        }
    }

    &__number {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 1.2em;

        p {
            color: rgba(85, 85, 85, 1);
            font-family: 'Roboto Condensed';
            font-size: 1em;
            font-weight: 400;
            text-transform: uppercase;
        }

        &Icon {
            font-size: 1em;
            min-width: 3em;
            opacity: 0.43;
        }

        &Title {
            display: flex;
            align-items: center;
            margin: 0 auto 0 0
        }
    }

    &__imageContainer {
        cursor: pointer;
        margin-top: 1.2em;
        width: calc(100% - 40px);
        position: relative;
        border-radius: 1.2em;
        background-color: #fff;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        flex-shrink: 0;

        &>img {
            object-fit: cover;
            height: 16.8em;
            width: 100%;
        }
    }

    &__imageHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.2em 1.8em;

        &>p {
            color: rgba(85, 85, 85, 1);
            font-family: 'Roboto';
            font-size: 1em;
            font-weight: 700;
        }
    }

    &__imageFooter {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.2em 1.8em;

        &Quantity {
            display: flex;
            justify-content: center;
            align-items: center;

            &>p {
                margin-left: 0.35em;
                color: rgba(85, 85, 85, 1);
                font-family: 'Roboto';
                font-size: 1em;
                font-weight: 700;
            }
        }
    }

    &__imageRemove {
        border: none;
        text-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
        color: rgba(229, 57, 53, 1);
        font-family: 'Roboto';
        font-style: normal;
        font-size: 1em;
        font-weight: 700;
        text-transform: uppercase;
        transition: color 0.2s, text-shadow 0.2s;

        &:hover,
        &:active {
            text-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
            filter: brightness(90%);
        }
    }

    &__imageAdd {
        cursor: pointer;
        margin: 1.2em 0;
        text-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
        color: var(--mobile-app-accent-color);
        font-family: 'Roboto';
        font-style: normal;
        font-size: 1em;
        font-weight: 700;
        text-transform: uppercase;
        transition: color 0.2s, text-shadow 0.2s;

        &:hover,
        &:active {
            text-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
            filter: brightness(90%);
        }
    }

    &__icon {
        display: flex;
        width: 3em;
        height: 3em;
        justify-content: center;
        align-items: center;
    }
}

.btn {
    width: 100%;
    margin: 0 10px;
    border-radius: 4px;
    background-color: var(--mobile-app-accent-color);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    flex-wrap: nowrap;
    cursor: pointer;
    z-index: 10;
    border: none;

    color: rgba(255, 255, 255, 0.9);
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    transition: filter 0.2s, box-shadow 0.2s;

    &:active,
    &:hover {
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
        filter: brightness(90%);
    }
}

.greyBtn {
    background-color: rgba(224, 224, 224, 1);

    color: rgba(0, 0, 0, 0.48);
}

.btnPanel {
    width: 100%;
    margin: auto 0 1.2em;
    padding: 0 0.6em;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .emptyBlock {
        width: 3.75em;
        visibility: hidden;
    }
}