import { construct, instance, overwrite, register } from '../../../../module/ui-kit/source/platform/Container';
import { DocumentPage } from '../Document/DocumentPage';

export const registerInstall = (container) => {
    container.installPage = register(construct(DocumentPage), 'navigateMain', 'insertInstall', 'getInstall', 'navigateInstallJournal', 'history', 'historyPopHandler', 'workerMessage', 'installJournalState');
    container.installCreateUrl = instance('/install/create');
    container.navigateInstallCreate = register((navigate, url) => (params) => navigate(url, params), 'navigate', 'installCreateUrl');
    overwrite(container, 'getRoutes', (getRoutes, Page, url) => () =>
        [...getRoutes(), {
            url,
            element: <Page title='Установка' addText='Добавьте зпу для установки' />
        }], 'base', 'installPage', 'installCreateUrl')
}