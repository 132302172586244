import { construct, instance, overwrite, register } from "../../module/ui-kit/source/platform/Container";
import { ToastContainer, toast } from 'react-toastify';

export const registerToast = (container) => {
    container.toastContainer = instance(ToastContainer);
    container.toast = register(construct(({ type = 'info', text, position = 'top-right', autoClose = 6000 }) =>
        toast[type](text, {
            position, autoClose
        })
    ));
    container.messageHandler = register(construct((toast, props) => {
        const { type, status, text } = props;

        if (type == 'installResponse') {
            return toast({ type: status, text });
        }
    }), 'toast');
    overwrite(container, 'messageHandler', construct((base, installJournalState, props) => {
        const { type, id } = props;

        if (type == 'syncInstall') {
            return installJournalState.update(id)
        }

        return base(props)
    }), 'base', 'installJournalState')
    overwrite(container, 'messageHandler', construct((base, props) => {
        const { type } = props;

        if (type == 'pong') {
            return window.dispatchEvent(new CustomEvent('pong'));
        }

        return base(props);
    }), 'base');
    overwrite(container, 'messageHandler', construct((base, installJournalState, props) => {
        const { type, id, status } = props;

        if (type == 'installProcessing') {
            return installJournalState.updateLoadingList(id, status);
        }

        return base(props)
    }), 'base', 'installJournalState')
}