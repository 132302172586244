import { useEffect, useState } from "react";
import { ListScreen } from "./ListScreen/ListScreen";

export const installJournalState = {
    lastUpdate: null,
    lastUpdatedDocumentId: null,
    loadingList: [],
    updateLoadingList: (id, status) => {
        if (status && !installJournalState.loadingList.includes(id)) {
            installJournalState.loadingList.push(id);
        } else if (!status) {
            installJournalState.loadingList = installJournalState.loadingList.filter(elem => elem !== id);
        }

        Object.values(installJournalState.subscribes).forEach(func => func());
    },
    update: (id) => {
        if (id) installJournalState.lastUpdatedDocumentId = id;
        installJournalState.lastUpdate = new Date();

        Object.values(installJournalState.subscribes).forEach(func => func());
    },
    subscribes: {},
    subscribe: (onChange, name) => {
        installJournalState.subscribes[name] = onChange;
        onChange({ lastUpdatedDocumentId: installJournalState.lastUpdatedDocumentId })
    },
}

export const InstallJournalPage = (getJournal, navigateCreate, navigateMain, installJournalState, workerMessage) => {
    const [documents, setDocuments] = useState(null);

    const refresh = () => workerMessage({ response: true });

    useEffect(() => {
        getJournal().then(documents => setDocuments(documents));

        installJournalState.subscribe(() => getJournal().then(documents => {
            setDocuments(documents.map(doc => ({ ...doc, loading: installJournalState.loadingList.includes(doc.id) })))
        }), 'journal')
    }, []);

    if (!documents) {
        return
    }

    return <ListScreen refresh={refresh} documents={documents} viewDocument={(id) => navigateCreate({ id })} navigateBack={() => navigateMain()} />
}