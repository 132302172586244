import * as React from "react";

export const LeftIcon = ({ color = '#fff', opacity = 1, width = "21.502", height = "21.004" }) => (
  <svg fill="none" width={width} xmlns="http://www.w3.org/2000/svg" viewBox="576.249 1870.498 21.502 21.004" height={height}>
    <g id="shape-7a61a798-47bc-8063-8003-baf5e1775072">
      <g id="fills-7a61a798-47bc-8063-8003-baf5e1775072">
        <path fillRule="evenodd" rx="0" ry="0" style={{ fill: color, fillOpacity: opacity }} d="M597.751,1881.000C597.751,1881.828,597.150,1882.500,596.407,1882.500L580.839,1882.500L586.609,1888.938C587.134,1889.525,587.134,1890.475,586.609,1891.062C586.083,1891.649,585.232,1891.649,584.706,1891.062L576.644,1882.062C576.391,1881.781,576.249,1881.398,576.249,1881.000C576.249,1880.602,576.391,1880.219,576.644,1879.938L584.706,1870.938C585.232,1870.351,586.083,1870.351,586.609,1870.938C587.134,1871.525,587.134,1872.475,586.609,1873.062L580.839,1879.500L596.407,1879.500C597.150,1879.500,597.751,1880.172,597.751,1881.000Z">
        </path>
      </g>
    </g>
  </svg>
)