.screen {
    &__block {
        background-color: #fff;
        margin: 1.2em 0;
        width: 100%;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
        padding: 0.95em 0 0.95em 0.35em;

        &>p {
            margin: 0 0 0.95em 0.95em;
            color: rgba(0, 0, 0, 0.22);
            font-family: 'Roboto';
            font-size: 0.8em;
            font-weight: 700;
            letter-spacing: 0;
            text-transform: uppercase;
        }
    }

    &__imageContainer {
        margin-bottom: 1.2em;
        width: calc(100% - 40px);
        position: relative;
        border-radius: 1.2em;
        background-color: #fff;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        flex-shrink: 0;
        height: 16.8em;

        &>img {
            object-fit: cover;
            height: 16.8em;
            width: 100%;
        }
    }

    &__imageFooter {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1.2em 1.8em;
    }

    &__imageRemove {
        cursor: pointer;
        border: none;
        text-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
        color: rgba(229, 57, 53, 1);
        font-family: 'Roboto';
        font-style: normal;
        font-size: 1em;
        font-weight: 700;
        text-transform: uppercase;
        transition: filter 0.2s, text-shadow 0.2s;

        &:hover,
        &:active {
            text-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
            filter: brightness(90%);
        }
    }

    &__imageAdd {
        cursor: pointer;
        margin: 1.9em 0;
        text-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
        color: var(--mobile-app-accent-color);
        font-family: 'Roboto';
        font-style: normal;
        font-size: 1em;
        font-weight: 700;
        text-transform: uppercase;
        transition: filter 0.2s, text-shadow 0.2s;

        &:hover,
        &:active {
            text-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
            filter: brightness(90%);
        }
    }
}

.item {
    padding-right: 1.2em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-family: 'Roboto Condensed';
        font-size: 1em;
        font-weight: 400;
        min-width: 2.3em;
        color: rgba(85, 85, 85, 1);
    }

    &__icon {
        font-size: 1em;
        min-width: 3em;
    }

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.btn {
    width: 100%;
    margin: 0 10px;
    border-radius: 4px;
    background-color: var(--mobile-app-accent-color);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.75em 1.5em;
    flex-wrap: nowrap;
    cursor: pointer;
    z-index: 10;
    border: none;

    color: rgba(255, 255, 255, 0.9);
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 1em;
    font-weight: 700;

    transition: filter 0.2s, box-shadow 0.2s;

    &:active,
    &:hover {
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
        filter: brightness(90%);
    }
}

.greyBtn {
    background-color: rgba(224, 224, 224, 1);

    color: rgba(0, 0, 0, 0.48);
}

.btnPanel {
    width: 100%;
    margin: auto 0 1.2em;
    padding: 0 0.6em;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .emptyBlock {
        width: 3.75em;
        visibility: hidden;
    }
}