.item {
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
    transition: background-color 0.2s;

    &__loader {
        width: 1.5em;
        margin: 0.75em;
    }

    &:hover,
    &:active {
        background-color: #ececec;
    }

    &__id {
        color: rgba(85, 85, 85, 1);
        font-family: 'Roboto';
        font-size: 0.9em;
        font-weight: 900;
    }

    &__info {
        display: flex;

        &>p {
            color: rgba(0, 0, 0, 0.43);
            font-family: 'Roboto';
            font-size: 0.8em;
            font-weight: 700;
            margin-left: 2px;
        }
    }

    &__lockIcon {
        margin-left: 0.15em;
        font-size: 0.9em;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.1em;
        font-size: 1em;
        min-width: 3em;
        height: 3em;
    }

    &__rightIcon {
        margin-left: auto;
        font-size: 1em;
        min-width: 3em;
    }
}