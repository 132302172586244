import { DefaultLayout } from '../../../../Layouts/DefaultLayout';
import { FolderIcon, PlusIcon } from '../../../../Assets/SvgIcons';
import * as styles from './EmptyListScreen.module.scss';
import { useEffect } from 'react';

export const EmptyListScreen = ({ add, back, historyPopHandler, title, addText }) => {
    useEffect(() => {
        const unlisten = historyPopHandler(back);

        return () => unlisten();
    }, []);

    return <DefaultLayout back={back} title={title}>
        <div className={styles.screen}>
            <p>{addText}</p>

            <button className={styles.screen__button} onClick={add}>
                <PlusIcon />
            </button>
        </div>
    </DefaultLayout>
}