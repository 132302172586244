@import "../../../../../Scss/Theme.scss";

.video {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 0;
}

.screen {
    background-color: #000;
}

.interactionLayer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 2em;
    z-index: 20;

    >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
    }
}

.message {
    padding: 0.5em 1.5em;
    border-radius: 20px;
    background-color: rgba($accent-color, 0.5);
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.2em;
    font-family: "Roboto";
    font-weight: 400;
}

.stamp {
    background-color: rgba($green-color, 0.8);
    display: flex;
    align-items: center;
    gap: 0.5em;

    &__loader {
        border-bottom-color: #fff;
    }
}

.btn {
    width: 3.75em;
    height: 3.75em;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    border-radius: 50%;
    z-index: 10;
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);

    transition: transform 0.2s, filter 0.2s, box-shadow 0.2s;

    &:active {
        transform: scale(0.9) !important;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
    }

    &:hover {
        filter: brightness(90%);
        transform: scale(1.04);
    }
}

.captureBtn {
    grid-area: capture;
    width: 80px;
    height: 80px;
    border: 5px solid rgba(255, 255, 255, 1);
    background-color: transparent;

    &:hover {
        transform: scale(1.04);
        filter: none;
    }
}

.rotateBtn {
    grid-area: rotate;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    &>img {
        width: 40px;
        height: 40px;
    }
}

.galleryBtn {
    width: 3.1em;
    height: 3.1em;
    background-color: var(--uikit-accent-background-color);
    position: absolute;
    top: 30px;
    left: 30px;

    p {
        color: rgba(255, 255, 255, 0.9);
        font-family: 'Roboto';
        font-size: 1.5em;
        font-weight: 700;
        line-height: 1.2;
    }
}

.coloredBtn {
    background-color: var(--uikit-accent-background-color);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.3);
}

.cameraButtons {
    display: grid;
    align-items: center;

    gap: 10px;
    grid-template-columns: 60px 80px 60px;
    grid-template-areas: " . capture rotate";
}

.warning {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon {
        font-size: 1.95em;
        margin-bottom: 30px;
    }

    & p {
        color: rgba(0, 0, 0, 1);
        font-family: 'Roboto';
        font-style: normal;
        font-size: 1em;
        font-weight: 300;
        line-height: 1.2;
        letter-spacing: 0px;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.mainLoader {
    width: 75px !important;
    height: 75px !important;
    border-width: 4px !important;
}