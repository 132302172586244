@import '../../scss/mixins.scss';

.iconWrap {
  @include topbar-icon-size;

  color: var(--uikit-accent-icon-color);
  display: flex;
  aspect-ratio: 1 / 1;
  justify-content: center;
  align-items: center;
  position: relative;
}