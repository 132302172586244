import { useEffect, useState } from "react"
import { DefaultListScreen } from "./DefaultListScreen/DefaultListScreen"
import { EmptyListScreen } from "./EmptyListScreen/EmptyListScreen"
import { StampScreen } from "../StampScreen/StampScreen";

export const StampListScreen = ({ document, deleteStamp, add, back, onConfirm, active, navigateJornal, onAddStamp, history, historyPopHandler, synchronize, title, addText }) => {
    const [stampIndex, setStampIndex] = useState(null);

    const viewStamp = (index) => {
        setStampIndex(index)
    }
    const addStamp = (stampInstall) => {
        onAddStamp(stampInstall, stampIndex);
        setStampIndex(null);
    }

    useEffect(() => {
        if (document?.stamps?.[stampIndex])
            history.push('#inactive')
    }, [])

    if (document?.stamps?.[stampIndex]) {
        return <StampScreen title={title} history={history} historyPopHandler={historyPopHandler} stampInstall={document?.stamps?.[stampIndex]} screen="confirm" onAddStamp={addStamp} back={() => setStampIndex(null)} active={active} />
    }

    return document?.stamps?.length
        ? <DefaultListScreen
            title={title}
            historyPopHandler={historyPopHandler} synchronize={synchronize}
            document={document} delete={deleteStamp} add={add} viewStamp={viewStamp} onConfirm={onConfirm}
            onCancel={back} active={active} navigateJornal={() => navigateJornal()} />
        : <EmptyListScreen addText={addText} title={title} historyPopHandler={historyPopHandler} add={add} back={back} />
}