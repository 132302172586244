.screen {
    background-color: #000;
    padding-bottom: 0;

    &__wrapper {
        width: 100% !important;
        height: 100% !important;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.layout {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.btn {
    width: 100%;
    margin: 0 10px;
    border-radius: 4px;
    background-color: var(--mobile-app-accent-color);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    flex-wrap: nowrap;
    cursor: pointer;
    z-index: 10;
    border: none;

    color: rgba(255, 255, 255, 0.9);
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    font-weight: 700;

    transition: filter 0.2s, box-shadow 0.2s;

    &:active,
    &:hover {
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
        filter: brightness(90%);
    }
}

.greyBtn {
    background-color: rgba(224, 224, 224, 1);

    color: rgba(0, 0, 0, 0.48);
}

.btnPanel {
    position: fixed;
    z-index: 2;
    width: 100%;
    bottom: 1.2em;
    padding: 0 0.6em;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .emptyBlock {
        width: 3.75em;
        visibility: hidden;
    }
}